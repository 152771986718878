<template>
    <section>
        <div class="row mx-0 px-3 mt-3 align-items-center">
            <tabs2 :tabs="tabs" />
            <div class="col-auto px-0 ml-auto">
                <el-badge :value="cant_msg_chat" :hidden="cant_msg_chat==0" class="item">
                    <i class="icon-message-text-outline text-general f-22 cr-pointer" @click="AbrirChat" />
                </el-badge>
            </div>
        </div>
        <router-view />
        <modalChat ref="modalChat" />
    </section>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            tabsReales: {
                tendero: [
                    { titulo: 'Dashboard', ruta: 'tendero.almacen.creditos.dashboard' },
                    { titulo: 'Deudas', ruta: 'tendero.almacen.creditos.cuotas' },
                    { titulo: 'Entregas', ruta: 'tendero.almacen.creditos.entregas' },
                    { titulo: 'Creditos', ruta: 'tendero.almacen.creditos.creditos' }
                ],

            }
        }
    },
    computed:{
        ...mapGetters({
            cant_msg_chat: 'almacen/credito_tendero/cant_msg_chat'
        }),
        tabs(){
            return this.tabsReales[this.$usuario.rol_nombre]
        }
    },
    mounted(){
        this.listarDatosGraficas()
    },
    methods:{
        listarDatosGraficas(){
            this.$store.commit('almacen/credito_tendero/set_grafica_por_pagar_meses', [])
            this.$store.dispatch('almacen/credito_tendero/action_listar_creditos_grafica_cuenta_por_pagar')
        },
        AbrirChat(){
            this.$store.commit('almacen/credito_tendero/set_cant_msg_chat', 0)
            this.$refs.modalChat.toggle(this.$usuario.id, 6, false, 3) // 6 = tipo credito pedido -admin y 3 para q diga habalr con proveedor
        },
    }

}
</script>
